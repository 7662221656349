.expandable {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .arrow {
    margin-left: 10px;
  }
  
  .content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
  }
  
  .content.show {
    max-height: 500px; 
    transition: max-height 0.5s ease-in;
  }
  
   @media (min-width: 1024px) {
    .content {
      max-height: none !important; 
    }

    .arrow {
        display: none;
    }

    footer {
        display: flex !important;
      }
  }
  