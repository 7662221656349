@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap");

.App {
  text-align: center;
}

body {
  background-color: #181818;
}

.hero {
  height: calc(100vh - var(--header-height));
}

.pswp__button--arrow--left,
.pswp__button--arrow--right {
  color: #f8a145;
}

.pswp__button .pswp__icn {
  transition: 0.2s ease-in-out;
}

.pswp__button:hover .pswp__icn {
  fill: #f8a145;
  scale: 1.1;
}

.pswp__custom-caption {
  background: rgba(75, 150, 75, 0.75);
  font-size: 16px;
  color: #fff;
  width: calc(100% - 32px);
  max-width: 400px;
  padding: 2px 8px;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);
}
.pswp__custom-caption a {
  color: #fff;
  text-decoration: underline;
}
.hidden-caption-content {
  display: none;
}

.arrow {
  display: inline-block;
  transition: transform 0.3s ease-in-out;
}

.rotate {
  transform: rotate(180deg);
}

.change-color {
  color: #f8a145;
}

@media only screen and (min-width: 768px) {
  .parent:hover .child {
    display: block;
    position: absolute;
    background-color: #1a1a1a;
    width: 100%;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    transition: opacity 0.3s ease-in-out;
  }
  .child {
    display: none;
    position: absolute;
    min-width: 160px;
    box-shadow: none;
    z-index: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation: fadeIn 2s ease-in forwards;
}

.info-icon {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-style {
  background-color: #181818;
  color: #f8a145;
  padding: 8px 16px;
  border: none;
  border-radius: 32px;
  margin: 10px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml,<svg height='20px' width='20px' viewBox='0 0 16 16' fill='%23F8A145' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.75rem) center;
  border: #f8a145 3px solid;
  transition: 0.3s ease-in-out;
}

.select-style:hover {
  border-color: #ffffff;
}

.select-style:focus {
  outline: none;
}

.select-style option {
  background: #181818;
  color: #f8a145;
}

.select-style::after {
  content: "▼";
  color: #f8a145;
  right: 15px;
  position: absolute;
  top: 14px;
  pointer-events: none;
}

.select-container {
  position: relative;
}

.indicatorWrapperUl {
  z-index: 2;
  padding: 0;
  margin: 0;
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}

.indicate {
  width: 25px;
  height: 25px;
}

.slideContainer {
  border: none;
}

.nextButton,
.prevButton {
  background-size: 100%;
  background-color: rgba(248, 161, 69, 0.8);
  height: 30px;
  width: 30px;
  border-radius: 4px;
  color: #fff;
  border: none;
  outline: 0;
  cursor: pointer;
  opacity: 0.5;
  transition: 0.3s;
}

.nextButton:hover,
.prevButton:hover {
  opacity: 1;
}

.fade-in {
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s ease-out, transform 2s ease-out, visibility 2s;
  will-change: opacity, transform, visibility;
}

.fade-in.show {
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 768px) {
  .showSmall {
    display: inline;
  }

  .hideSmall {
    display: none;
  }
}

@media screen and (min-width: 780px) {
  .showBig {
    display: inline;
  }

  .hideBig {
    display: none;
  }
}
