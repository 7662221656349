.focus\:border-orange:focus {
  border-color: #F8A145;
}

.fade-out {
  animation: fadeOut 1s forwards;
}

@keyframes fadeOut {
  0% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(20px); }
}